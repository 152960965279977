@import "../../../../utilities/variables";

.digital-transformation-container {
  > h2 {
    text-align: center;
  }

  > .journey {
    margin-top: 100px;
    display: grid;
    grid-gap: 10px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "s1 s1 s2 s2 s3 s3 s4 s4"
    "sr sr s6 s6 s6 s6 s5 s5"
    "sr sr s7 s7 s7 s7 s7 s7";

    @media only screen and (max-width: 1130px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
    "s1 s1 s1 sr sr sr sr . ."
    "s2 s2 s2 s3 s3 s3 s4 s4 s4"
    "s6 s6 s6 s6 s6 s5 s5 s5 s5"
    "s7 s7 s7 s7 s7 s7 s7 s7 s7";
    }

    @media only screen and (max-width: 810px) {
      grid-template-areas:
    "s1 s1 s1 s1 sr sr sr sr sr"
    "s2 s2 s2 s2 s3 s3 s3 s3 s3"
    "s4 s4 s4 s4 s4 s4 s4 s4 s4"
    "s6 s6 s6 s6 s6 s5 s5 s5 s5"
    "s7 s7 s7 s7 s7 s7 s7 s7 s7";

      .journey-item.consultation > .arrow {
        transform: rotateZ(90deg);
        left: unset;
        right: 6px;
        top: 44px;
      }
    }

    @media only screen and (max-width: 650px) {
      grid-template-areas:
    "s1 s1 s1 s1 sr sr sr sr sr"
    "s2 s2 s2 s2 s3 s3 s3 s3 s3"
    "s4 s4 s4 s4 s4 s4 s4 s4 s4"
    "s5 s5 s5 s5 s5 s5 s5 s5 s5"
    "s6 s6 s6 s6 s6 s6 s6 s6 s6"
    "s7 s7 s7 s7 s7 s7 s7 s7 s7";

      .journey-item.dev > .arrow {
        transform: rotateZ(90deg);
        left: auto;
        right: auto;
        top: 52px;
        display: flex;
      }
    }

    @media only screen and (max-width: 460px) {
      .journey-item.dev > .arrow {
        top: 50px;
      }
    }

    @media only screen and (max-width: 590px) {
      grid-template-areas:
    "sr sr sr sr sr sr sr sr sr"
    "s1 s1 s1 s1 s1 s1 s1 s1 s1"
    "s2 s2 s2 s2 s2 s2 s2 s2 s2"
    "s3 s3 s3 s3 s3 s3 s3 s3 s3"
    "s4 s4 s4 s4 s4 s4 s4 s4 s4"
    "s5 s5 s5 s5 s5 s5 s5 s5 s5"
    "s6 s6 s6 s6 s6 s6 s6 s6 s6"
    "s7 s7 s7 s7 s7 s7 s7 s7 s7";

      .journey-item.consultation > .arrow {
        transform: rotateZ(90deg);
        left: -16px;
        right: unset;
        top: 44px;
      }

      .journey-item.initial > .arrow {
        transform: rotateZ(90deg);
        left: unset;
        right: 6px;
        top: 44px;
      }
    }

    @media only screen and (max-width: 430px) {
      .journey-item.consultation > .arrow,
      .journey-item.initial > .arrow,
      .journey-item.plan > .arrow,
      .journey-item.qa-uat > .arrow {
        top: 40px;
      }
    }

    > .journey-item {
      position: relative;
      text-align: center;
      padding: 15px 30px;
      margin: 5px;
      background-color: $colour1;
      border-radius: 8px;
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3)) grayscale(1);

      font-size: 16px;
      font-weight: 600;

      i {
        font-size: 12px;
        font-weight: initial;

        > .highlight {
          color: $colour3;
          font-weight: 600;
        }
      }

      display: flex;
      justify-content: center;

      > div {
        align-self: center;
      }

      > .arrow {
        position: absolute;
        top: 10px;
        right: -35px;
        width: 50px;
        height: 100%;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        z-index: 1;

        > svg {
          display: block;
          font-size: 34px;
          align-self: center;
          margin-top: 6px;
        }
      }

      @media only screen and (max-width: 460px) {
        > div {
          font-size: 14px;
        }

        i {
          font-size: 11px;
        }
      }

      @media only screen and (max-width: 430px) {
        > div {
          font-size: 12px;
        }

        i {
          font-size: 10px;
        }
      }
    }

    > .more-reading-section {
      grid-area: sr;
      text-align: center;

      display: flex;
      justify-content: center;
      flex-direction: column;

      > i > a {
        color: $colour3;
        text-decoration: none;
      }
    }

    > .call {
      grid-area: s1;
      align-items: center;
      display: flex;

      > button {
        width: 100%;
      }
    }

    > .initial {
      grid-area: s2;
    }

    > .consultation {
      grid-area: s3;
    }

    > .plan {
      grid-area: s4;

      > .arrow {
        right: -20px;
        top: unset;
        bottom: -47px;
      }
    }

    > .dev {
      grid-area: s5;

      > .arrow {
        left: -27px;
        top: 10px;
      }
    }

    > .qa-uat {
      grid-area: s6;
      z-index: -1;

      > .arrow {
        left: 10px;
        top: unset;
        bottom: -47px;
      }
    }

    > .pr-support {
      grid-area: s7;
      display: block;

      > .text {
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          width: 170px;

          @media only screen and (max-width: 580px) {
            width: 200px;

            &.plus {
              width: 120px;
            }
          }

          @media only screen and (max-width: 500px) {
            &.plus {
              width: 50px;
            }
          }

          @media only screen and (max-width: 380px) {
            &.plus {
              width: 10px;
            }
          }
        }
      }
    }
  }
}