.services-section {
  > .card {
    > .content {
      display: flex;
      justify-content: space-around;
      flex-direction: row;

      @media screen and (max-width: 790px) {
        flex-direction: column;
      }

      > .service-card {
        width: 100%;
        text-align: center;
        margin: 50px 0;

        display: flex;
        justify-content: space-between;
        flex-direction: column;

        > button {
          align-self: center;

          @media screen and (max-width: 940px) {
            padding: 15px 40px;
          }

          @media screen and (max-width: 840px) {
            padding: 15px 20px;
          }

          @media screen and (max-width: 790px) {
            padding: 15px 60px;
          }
        }

        > .service-image {
          > img {
            height: 200px;
          }
        }

        > .description {
          margin: 50px;

          @media screen and (max-width: 1160px) {
            margin: 50px 20px;
          }

          @media screen and (max-width: 1070px) {
            margin: 50px 10px;
          }

          @media screen and (max-width: 1020px) {
            font-size: 14px;
          }

          @media screen and (max-width: 940px) {
            font-size: 13px;
          }

          @media screen and (max-width: 840px) {
            font-size: 12px;
          }

          @media screen and (max-width: 790px) {
            font-size: 16px;
            margin: 50px 100px;
          }

          @media screen and (max-width: 600px) {
            font-size: 16px;
            margin: 50px 50px;
          }

          @media screen and (max-width: 600px) {
            font-size: 16px;
            margin: 50px 20px;
          }
        }
      }
    }
  }
}