﻿$start: 0;
$end: 20;

@for $i from $start through $end {
  .fadeInUp--#{$i} {
    animation: fadeInUp 1s ease backwards;
    animation-delay: calc(#{$i} * 100ms);
    animation-play-state: paused;
  }
}

@for $i from $start through $end {
  .front--#{$i} {
    position: relative;
    z-index: calc(#{$i} * 5);;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}