﻿main {
  padding: 100px 0;
}

$start: 0;
$end: 40;

@for $i from $start through $end {
  .mx--#{$i} {
    margin: 0  calc(#{$i} * 10px);

    @media screen and (max-width: 1080px) {
      margin: 0  calc(#{$i} * 5px);
    }

    @media screen and (max-width: 740px) {
      margin: 0  calc(#{$i} * 2px);
    }

    @media screen and (max-width: 600px) {
      margin: 0  0;
    }
  }
}

@for $i from $start through $end {
  .my--#{$i} {
    margin: calc(#{$i} * 10px) 0;
  }
}

.mbl {
  margin-bottom: 250px;
}

.my1 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mx1 {
  margin-right: 100px;
  margin-left: 100px;
}

.mb1 {
  margin-bottom: 100px;
}

.mt1 {
  margin-top: 100px;
}

.my2 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mx2 {
  margin-right: 80px;
  margin-left: 80px;
}

.mb2 {
  margin-bottom: 80px;
}

.mt2 {
  margin-top: 80px;
}

.my3 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mx3 {
  margin-right: 50px;
  margin-left: 50px;
}

.mb3 {
  margin-bottom: 50px;
}

.mt3 {
  margin-top: 50px;
}

.my4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx4 {
  margin-right: 20px;
  margin-left: 20px;
}

.mb4 {
  margin-bottom: 20px;
}

.mt4 {
  margin-top: 20px;
}

.my5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx5 {
  margin-right: 10px;
  margin-left: 10px;
}

.mb5 {
  margin-bottom: 10px;
}

.mt5 {
  margin-top: 10px;
}

.py1 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.px1 {
  padding-right: 100px;
  padding-left: 100px;
}

.pb1 {
  padding-bottom: 100px;
}

.pt1 {
  padding-top: 100px;
}

.py2 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.px2 {
  padding-right: 80px;
  padding-left: 80px;
}

.pb2 {
  padding-bottom: 80px;
}

.pt2 {
  padding-top: 80px;
}

.py3 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.px3 {
  padding-right: 50px;
  padding-left: 50px;
}

.pb3 {
  padding-bottom: 50px;
}

.pt3 {
  padding-top: 50px;
}

.py4 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.px4 {
  padding-right: 20px;
  padding-left: 20px;
}

.pb4 {
  padding-bottom: 20px;
}

.pt4 {
  padding-top: 20px;
}

.py5 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px5 {
  padding-right: 10px;
  padding-left: 10px;
}

.pb5 {
  padding-bottom: 10px;
}

.pt5 {
  padding-top: 10px;
}

.w100 {
  width: 100%;
}