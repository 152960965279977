@import "../utilities/_variables.scss";

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  &.scrolled {
    background-color: $colour2;
    background-image: linear-gradient(to right, #1F1F1F, $colour2);
  }
}

main {
  margin: 100px 300px 0;

  @media only screen and (max-width: 1800px) {
    margin: 100px 250px 0;
  }

  @media only screen and (max-width: 1700px) {
    margin: 100px 200px 0;
  }

  @media only screen and (max-width: 1600px) {
    margin: 100px 150px 0;
  }

  @media only screen and (max-width: 1500px) {
    margin: 100px 100px 0;
  }

  @media only screen and (max-width: 1450px) {
    margin: 100px 80px 0;
  }

  @media only screen and (max-width: 1300px) {
    margin: 100px 40px 0;
  }

  @media only screen and (max-width: 940px) {
    margin: 100px 30px 0 30px;
  }

  @media only screen and (max-width: 460px) {
    margin: 100px 20px 0;
  }
}