@import "../../utilities/variables";

.contacted {
  min-height: 680px;
  grid-area: a2;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  > img {
    max-width: 360px;
  }
}

.contact-container {  
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(10, 50px);
  grid-gap: 20px;
  grid-template-areas: 
          "a1 ."
          "a1 a6"
          "a1 a6"
          "a2 a6"
          "a2 a6"
          "a2 a6"
          "a2 a6"
          "a2 a5"
          "a2 a5"
          "a2 a3";

  @media screen and (max-width: 1020px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 
          "a1 a1 a1 .  . . ."
          "a1 a1 a1 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a5 a5 a5 a5"
          "a2 a2 a2 a5 a5 a5 a5"
          "a2 a2 a2 a3 a3 a3 a3";
  }

  @media only screen and (max-width: 890px) {
    grid-template-rows: repeat(10, 55px);
    grid-template-areas: 
          "a1 a1 a1 .  . . ."
          "a1 a1 a1 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a6 a6 a6 a6"
          "a2 a2 a2 a5 a5 a5 a5"
          "a2 a2 a2 a5 a5 a5 a5"
          "a2 a2 a2 a3 a3 a3 a3";
  }

  @media only screen and (max-width: 890px) {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-template-areas: 
            "a1"
            "a2"
            "a3"
            "a5"
            "a6"
            "a6";
  }
  
  > .card {
    > .content {
      text-align: center;
    }
  }
  
  > .header {
    grid-area: a1;
  }

  > .contact-form {
    grid-area: a2;
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 10px;
    grid-template-areas: 
            "fn ln"
            "em em"
            "ph ph"
            "de de"
            "su su";

    @media screen and (max-width: 1020px) {
      grid-template-rows: repeat(6, 1fr);
      grid-template-areas: 
            "fn fn"
            "ln ln"
            "em em"
            "ph ph"
            "de de"
            "su su";
    }

    @media only screen and (max-width: 890px) {
      grid-template-rows: unset;
      grid-gap: 20px;
      grid-template-areas: 
            "fn ln"
            "em em"
            "ph ph"
            "de de"
            "su su";
    }

    @media only screen and (max-width: 480px) {
      grid-template-areas: 
            "fn fn"
            "ln ln"
            "em em"
            "ph ph"
            "de de"
            "su su";
    }
    
    .first-name {
      grid-area: fn;
    }

    .last-name {
      grid-area: ln;
    }

    .email {
      grid-area: em;
    }

    .phone {
      grid-area: ph;
    }

    .description {
      grid-area: de;
    }

    .submit {
      grid-area: su;
      align-self: end;

      > .spinner {
        transform: scale(0.6);
      }
      
      > .error {
        text-align: center;
        margin-bottom: 5px;
        display: none;
        
        &.show {
          display: initial;
        }
      }
      
      > .book-call {
        position: relative;
        
        > .arrow {
          display: none;

          @media only screen and (max-width: 890px) {
            position: absolute;
            bottom: -20px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
          }
        }
        
        > svg {
          position: absolute;
          right: 25px;

          @media only screen and (max-width: 890px) {
            display: none;
          }
        }
      }
    }
  }

  > .initial {
    grid-area: a3;
    
    position: relative;
    
    > .arrow {
      position: absolute;
      top: -18px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 890px) {
        transform: rotateX(180deg);
        top: unset;
        bottom: -18px;
      }
    }
    
    > .content > div {
      margin: 0;
    }
  }

  > .consultation {
    grid-area: a4;
  }

  > .statement {
    grid-area: a5;
    
    display: flex;
    justify-content: center;
    align-self: center;
    font-style: italic;
    
    text-align: center;
    padding: 0 80px;

    @media only screen and (max-width: 460px) {
      padding: 0 40px;
    }
  }

  > .testimony {
    grid-area: a6;
    height: fit-content;
    filter: grayscale(0.3);
    
    > .image {
      > img {
        object-position: 0 30%;
      }
    }
    
    margin: 0 150px;

    @media only screen and (max-width: 1350px) {
      margin: 0 120px;
    }

    @media only screen and (max-width: 1150px) {
      margin: 0 100px;
    }
    
    @media only screen and (max-width: 1040px) {
      margin: 0 80px;
    }

    @media only screen and (max-width: 890px) {
      margin: 0 250px;
    }

    @media only screen and (max-width: 820px) {
      margin: 0 220px;
    }

    @media only screen and (max-width: 780px) {
      margin: 0 200px;
    }

    @media only screen and (max-width: 730px) {
      margin: 0 150px;
    }

    @media only screen and (max-width: 650px) {
      margin: 0 100px;
    }

    @media only screen and (max-width: 550px) {
      margin: 0;
    }
  }
}