@import "../../utilities/variables";

.footer-container {
  width: 100%;
  min-height: 50vh;
  background-image: linear-gradient(to right, #1F1F1F, $colour2);
  display: flex;
  justify-content: center;
  gap: 100px;

  padding: 0 0 50px 0;

  @media only screen and (max-width: 780px) {
    padding: 50px 0;
  }

  @media only screen and (max-width: 1320px) {
    gap: 50px;
  }

  @media only screen and (max-width: 1180px) {
    gap: 20px;
  }

  @media only screen and (max-width: 1050px) {
    flex-direction: column;
  }

  .logo-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $colour1;

    @media only screen and (max-width: 1050px) {
      margin-top: 20px;
      align-items: center;
      text-align: center;
    }

    > div {
      padding-left: 3px;

      > h4 {
        margin: 8px 0;
      }
    }
  }

  > .link-section {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 100px;
    color: $colour1;

    @media only screen and (max-width: 1280px) {
      gap: 50px;
    }

    @media only screen and (max-width: 1140px) {
      gap: 20px;
    }

    @media only screen and (max-width: 1050px) {
      margin-top: 20px;
    }

    @media only screen and (max-width: 720px) {
      margin-top: 10px;
    }

    @media only screen and (max-width: 780px) {
      text-align: center;
      display: grid;
      grid-template-areas: 
        "a1 a2"
        "a3 a3";

      > .explore {
        grid-area: a1;
      }

      > .website {
        grid-area: a2;
      }

      > .company {
        grid-area: a3;
      }
    }

    @media only screen and (max-width: 400px) {
      grid-template-areas: 
        "a1"
        "a2"
        "a3";
    }

    h3 {
      color: $colour3;
      padding: 10px 30px;
    }

    ul {
      padding-left: 0;

      > li {
        width: fit-content;
        display: flex;
        list-style: none;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        border-radius: 20px;
        margin: 20px 0;
        transition: all 0.2s linear;

        @media only screen and (max-width: 780px) {
          width: 100%;
          display: block;
        }

        &.info {
          margin: 0 0;
          font-weight: 400;

          > a {
            padding: 5px 30px;
          }
        }

        &:hover {
          background-color: #1F1F1F;
        }

        > a {
          text-decoration: none;
          color: $colour1;
          padding: 10px 30px;
        }

        > div {
          padding: 10px 30px;
        }
      }
    }
  }
}