.not-found-container {
  text-align: center;
  
  > .not-found-img {
    margin-bottom: 100px;
    
    > img {
      width: 360px;

      @media screen and (max-width: 520px) {
        width: 100%;
      }
    }
  }
}