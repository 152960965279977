.about-container {

  @media only screen and (max-width: 460px) {
    margin: 60px 0;
  }

  > .main {
    display: flex;
    justify-content: space-between;

    margin-bottom: 100px;

    @media only screen and (max-width: 720px) {
      flex-direction: column;
      gap: 40px;
      margin-bottom: 50px;
    }

    > .header {
      width: 100%;

      @media only screen and (max-width: 940px) {
        margin-top: 0;
      }

      > h1,
      > h3 {
        margin: 0;
      }

      > .about-text {
        margin-top: 20px;
        font-size: 14px;
      }

      > .startup-text {
        margin-top: 80px;

        @media only screen and (max-width: 780px) {
          margin-top: 30px;
        }
      }
    }

    > .image-section {
      width: 100%;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 780px) {
        justify-content: end;
      }

      > .card {
        width: 450px;
        height: 500px;

        @media only screen and (max-width: 940px) {
          width: 300px;
          height: 350px;
        }

        @media only screen and (max-width: 720px) {
          width: 100%;
          height: 200px;
        }

        > .image {
          height: 100%;
          display: flex;
          padding: 0;

          > picture > img,
          > img {
            border-radius: 8px;
            object-fit: cover;
          }
        }
      }
    }
  }

  > .vision {
    display: flex;
    justify-content: start;
    gap: 50px;
    margin: 150px 50px 0 50px;

    @media only screen and (max-width: 940px) {
      margin: 150px 0 0 0;
    }

    @media only screen and (max-width: 780px) {
      gap: 20px;
    }

    @media only screen and (max-width: 720px) {
      margin: 50px 0 0 0;
      flex-direction: column-reverse;
    }

    > .vision-statement {
      width: 100%;
    }

    > .vision-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media only screen and (max-width: 940px) {
        margin: 90px 50px 0 0;
      }

      @media only screen and (max-width: 780px) {
        margin: 90px 0 0 0;
      }

      @media only screen and (max-width: 720px) {
        margin: 20px 0 0 0;
      }
    }
  }

  > .values {
    margin-top: 150px;

    @media only screen and (max-width: 720px) {
      margin-top: 80px;
    }

    > .values-list {
      margin-top: 50px;

      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr 1fr;

      @media only screen and (max-width: 780px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr 1fr;
      }

      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(6, 1fr);
      }

      > .card {
        text-align: center;
      }
    }
  }

  > .team {
    margin-top: 150px;

    @media only screen and (max-width: 720px) {
      margin-top: 80px;
    }

    > .team-images {
      margin-top: 50px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 100px;

      @media only screen and (max-width: 780px) {
        grid-gap: 20px;
      }

      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }

      > .card {
        text-align: center;
        width: 300px;
        justify-self: center;

        @media only screen and (max-width: 780px) {
          width: 250px;
        }

        @media only screen and (max-width: 600px) {
          width: 300px;
        }
      }
    }
  }
}