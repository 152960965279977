﻿@import "_variables.scss";

button {
  padding: 15px 60px;
  border-radius: 30px;
  border: 2px solid $colour2;
  font-weight: 600;
  font-size: 16px;
  background-color: $colour1;
  width: fit-content;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  > span {
    margin: 8px;
  }

  &.primary {
    background-color: $colour3;
    border: 2px solid $colour3;

    &:hover {
      background-color: $colour4;
      border: 2px solid $colour4;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $colour6;
  }
}