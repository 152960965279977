.trusted-section {
  h2 {
    text-align: center;
  }
  
  > .trusted-container {
    margin-top: 50px;
    
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 2fr;

    .testimony {
      filter: grayscale(0.3);
    }

    @media only screen and (max-width: 1160px) {      
      .wellocks {
        grid-area: a1;
        display: flex;
        justify-content: center;
      }

      .wjfg {
        grid-area: a2;
        display: flex;
        justify-content: center;
      }

      .belazu {
        grid-area: a3;
        display: flex;
        justify-content: center;
      }

      .testimony {
        grid-area: a4;
        filter: none !important;
      }

      grid-template-columns: auto;
      grid-template-rows: 200px;
      grid-template-areas: 
        "a1 a2 a3"
        "a4 a4 a4";
    }

    @media only screen and (max-width: 580px) {
      margin: 50px 10px 0 10px;
      grid-template-rows: 200px 200px;
      grid-template-areas: 
        "a1 a1 a3 a3"
        "a2 a2 a2 a2"
        "a4 a4 a4 a4";
    }
  }
}