.consultation-section {
  > .main {
    > .header {
      text-align: center;
    }
  }

  > .services {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 560px) {
      grid-template-columns: repeat(1, 1fr);
    }

    > div {
      padding: 20px;

      > h5 {
        margin-bottom: 10px;
      }
    }

    > div:not(:last-of-type) {
      border-right: 1px solid black;

      @media screen and (max-width: 900px) {
        border-right: none;
      }
    }
  }

  > .fast-delivery {
    > .fast-delivery-container {
      display: flex;
      justify-content: space-between;
      gap: 50px;
      margin: 0 180px;

      @media screen and (max-width: 1390px) {
        gap: 20px;
        margin: 0 160px;
      }

      @media screen and (max-width: 1250px) {
        gap: 10px;
        margin: 0 120px;
      }

      @media screen and (max-width: 1140px) {
        gap: 10px;
        margin: 0 80px;
      }

      @media screen and (max-width: 1020px) {
        gap: 10px;
        margin: 0 40px;
      }

      @media screen and (max-width: 940px) {
        gap: 5px;
        margin: 0 20px;
      }

      @media screen and (max-width: 820px) {
        flex-direction: column-reverse;
        align-items: center;
      }
      
      > .text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
      }
      
      > .image {
        display: flex;
        justify-content: center;
        flex-direction: column;
        filter: grayscale(1);
        
        > img {
          width: 450px;

          @media screen and (max-width: 450px) {
            width: 360px;
          }
        }
      }
    }
  }

  > .fast-delivery,
  > .technology-partners,
  > .expectation {
    > h3,
    > h2{
      text-align: center;
    }
  }

  > .expectation {
    > .expectation-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;

      @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      }
    }
  }
  
  > .technology-partners {
    > .technology-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 50px;
      padding: 50px;

      @media screen and (max-width: 820px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
      
      > .card {
        height: 162px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > .image {
          
        }
      }
    }
  }

  > .contact {
    text-align: center;
  }
}