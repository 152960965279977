.header-container {
  height: 74px;
  padding: 20px 300px 0 300px;
  display: flex;
  justify-content: space-between;
  transition: all 80ms linear;

  &.scrolled {
    padding: 0 300px 0 300px;
  }

  @media only screen and (max-width: 1800px) {
    padding: 20px 250px 0 250px;

    &.scrolled {
      padding: 0 250px 0 250px;
    }
  }

  @media only screen and (max-width: 1700px) {
    padding: 20px 200px 0 200px;

    &.scrolled {
      padding: 0 200px 0 200px;
    }
  }

  @media only screen and (max-width: 1600px) {
    padding: 20px 150px 0 150px;

    &.scrolled {
      padding: 0 150px 0 150px;
    }
  }

  @media only screen and (max-width: 1500px) {
    padding: 20px 100px 0 100px;

    &.scrolled {
      padding: 0 100px 0 100px;
    }
  }

  @media only screen and (max-width: 1450px) {
    padding: 20px 80px 0 80px;

    &.scrolled {
      padding: 0 80px 0 80px;
    }
  }

  @media only screen and (max-width: 1300px) {
    padding: 20px 40px 0 40px;

    &.scrolled {
      padding: 0 40px 0 40px;
    }
  }

  @media only screen and (max-width: 940px) {
    padding: 20px 10px 0 40px;

    &.scrolled {
      padding: 0 10px 0 40px;
    }
  }

  @media only screen and (max-width: 460px) {
    padding: 20px 20px 0 20px;

    &.scrolled {
      padding: 0 20px 0 20px;
    }
  }

  > div > svg {
    @media only screen and (max-width: 460px) {
      width: 180px;
    }
  }
}

.menu-container {
  display: flex;
}