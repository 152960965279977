.technologies-container {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1250px) {
    margin-bottom: 200px;
  }

  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    margin-bottom: 400px;
  }
  
  @media only screen and (max-width: 640px) {
    margin-bottom: 50px;
  }

  > .statement {
    width: 100%;

    @media only screen and (max-width: 580px) {
      > h1 {
        font-size: 2.2em;
      }

      > h3 {
        font-size: 0.7em;
      }
    }

    @media only screen and (max-width: 496px) {
      > h1 {
        font-size: 2em;
      }

      > h3 {
        font-size: 0.6em;
      }
    }

    @media only screen and (max-width: 496px) {
      > h1 {
        font-size: 1.8em;
      }
    }
  }

  > .tech-company-logos {
    position: relative;
    top: -30px;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1080px) {
      top: 80px;
      left: 0;
    }

    > div > .image {
      width: 150px;
      height: 40px;
      padding: 20px;
      background-color: white;
      border-radius: 8px;
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3)) grayscale(1);
      position: absolute;

      @media only screen and (max-width: 640px) {
        width: unset;
        text-align: center;
      }

      &.microsoft {
        top: 0;
        left: 260px;
        animation: MoveUpDown 9s ease-in-out infinite;

        @media only screen and (max-width: 1080px) {
          top: 0;
          left: 350px;
        }

        @media only screen and (max-width: 760px) {
          top: 0;
          left: 280px;
        }

        @media only screen and (max-width: 680px) {
          top: 0;
          left: 220px;
        }
      }

      &.amazon {
        top: 150px;
        left: 400px;
        animation: MoveUpDown 5s ease-in-out infinite;

        @media only screen and (max-width: 1250px) {
          top: 140px;
          left: 200px;
        }

        @media only screen and (max-width: 1080px) {
          top: 120px;
          left: 600px;
        }

        @media only screen and (max-width: 880px) {
          top: 120px;
          left: 550px;
        }

        @media only screen and (max-width: 810px) {
          top: 120px;
          left: 490px;
        }

        @media only screen and (max-width: 760px) {
          top: 120px;
          left: 430px;
        }

        @media only screen and (max-width: 680px) {
          top: 120px;
          left: 380px;
        }
      }

      &.google {
        top: 100px;
        left: 20px;
        animation: MoveUpDown 7s ease-in-out infinite;

        @media only screen and (max-width: 1250px) {
          top: 40px;
          left: -20px;
        }

        @media only screen and (max-width: 1080px) {
          top: 60px;
          left: 50px;
        }

        @media only screen and (max-width: 680px) {
          top: 60px;
          left: 0;
        }
      }

      &.meta {
        top: 250px;
        left: 200px;
        animation: MoveUpDown 11s ease-in-out infinite;

        @media only screen and (max-width: 1250px) {
          top: 230px;
          left: -80px;
        }

        @media only screen and (max-width: 1080px) {
          top: 180px;
          left: 300px;
        }

        @media only screen and (max-width: 810px) {
          top: 180px;
          left: 250px;
        }

        @media only screen and (max-width: 760px) {
          top: 180px;
          left: 200px;
        }

        @media only screen and (max-width: 680px) {
          top: 180px;
          left: 140px;
        }
      }

      @media only screen and (max-width: 640px) {
        position: initial;

        &.microsoft,
        &.amazon,
        &.google,
        &.meta {
          animation: none;
          box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
          filter: grayscale(1);
        }
      }

      > img {
        width: 150px;

        //@media only screen and (max-width: 640px) {
        //  width: unset;
        //}
      }
    }

    @media only screen and (max-width: 640px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      > .image {
        text-align: center;
        padding-top: 30px;
      }
    }

    @media only screen and (max-width: 460px) {
      grid-template-columns: 1fr;
    }
  }
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
}