.blog-container {
  > .title {
    margin-bottom: 120px;
    
    > h1 {
      width: 80%;

      @media only screen and (max-width: 620px) {
        width: 100%;
        text-align: center;
      }
    }
    
    > .date-time {
      margin-top: 20px;
      margin-left: 5px;

      @media only screen and (max-width: 620px) {
        width: 100%;
        text-align: center;
      }
    }
  }
  
  > .card {
    margin-bottom: 50px;
    
    > .image {
      height: 480px;
    }
  }
  
  > .blog-details {
    img {
      max-height: 600px;
      max-width: 100%;
    }

    pre > code {
      display: block;
      background: #1F1F1F;
      color: #ffffff;
      padding: 1.33em;
      border-radius: 0.33em;
    }

    pre:has(> code) {
      background: #1F1F1F;
      color: #ffffff;
      padding: 1.33em;
      border-radius: 0.33em;
    }

    pre:has(code) {
      background: #1F1F1F;
      color: #ffffff;
      padding: 1.33em;
      border-radius: 0.33em;
    }

    pre:has(code) {
      white-space: pre-wrap;
      tab-size: 3;
      overflow: auto;
    }
    
    > .author.right {
      text-align: right;
    }
  }

  > .contact {
    margin: 200px 0;
    text-align: center;

    > h2 {
      margin-bottom: 50px;
    }
  }
}