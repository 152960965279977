.main-container {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  
  @media only screen and (max-width: 580px) {
    justify-content: center;
  }
  
  .main-text {
    z-index: 20;

    @media only screen and (max-width: 580px) {
      text-align: center;
    }
    
    > .mission {
      display: initial;
      
      @media only screen and (max-width: 540px) {
        display: none;
      }
    }
    
    > .mission-mobile {
      display: none;

      @media only screen and (max-width: 540px) {
        display: initial;
      }
    }
    
    > div > button {
      @media only screen and (max-width: 580px) {
        margin-top: 250px;
      }
    }
  }
  
  > .image-container {
    position: relative;

    @media only screen and (max-width: 380px) {
      right: -10px;
    }
    
    > div > img {
      width: calc(300px * var(--height-ratio));
      height: calc(300px * var(--height-ratio));
      object-fit: cover;
      border-radius: 20px;
      filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3)) grayscale(1);

      @media only screen and (max-width: 840px) {
        filter: grayscale(1);
        opacity: 0.8;
      }

      @media only screen and (max-width: 800px) {
        opacity: 0.4;
      }

      @media only screen and (max-width: 580px) {
        opacity: 0.3;
      }
      
      &.manufacturing {
        animation: MoveUpDownAnRotate 5s ease-in-out infinite;
        position: absolute;
        top: 0;
        right: -20px;
        z-index: 20;

        @media only screen and (max-width: 940px) {
          right: 20px;
        }

        @media only screen and (max-width: 460px) {
          width: 200px;
          height: 200px;
          right: 0;
        }
      }
      
      &.code {
        width: calc(200px * var(--height-ratio));
        height: calc(200px * var(--height-ratio));
        animation: MoveUpDown 9s ease-in-out infinite;
        position: absolute;
        top: calc(-120px * var(--height-ratio));
        right: calc(230px * var(--height-ratio));
        z-index: 9;

        @media only screen and (max-width: 1100px) {
          top: calc(-130px * var(--height-ratio));
        }

        @media only screen and (max-width: 1000px) {
          top: calc(-140px * var(--height-ratio));
        }

        @media only screen and (max-width: 460px) {
          width: 150px;
          height: 150px;
        }
      }

      &.tasks {
        width: calc(250px * var(--height-ratio));
        height: calc(250px * var(--height-ratio));
        animation: MoveRightLeft 9s ease-in-out infinite;
        position: absolute;
        top: calc(220px * var(--height-ratio));
        right: calc(250px * var(--height-ratio));
        z-index: 9;

        @media only screen and (max-width: 1100px) {
          top: calc(240px * var(--height-ratio));
        }

        @media only screen and (max-width: 1000px) {
          top: calc(260px * var(--height-ratio));
        }

        @media only screen and (max-width: 460px) {
          width: 180px;
          height: 180px;
          top: 180px;
          right: 180px
        }

        @media only screen and (max-width: 400px) {
          right: 150px
        }
      }
    }
  }
}

@keyframes MoveUpDownAnRotate {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(10px) translateX(0px);
  }
  50% {
    transform: translateY(0px) translateX(10px);
  }
}

@keyframes MoveRightLeft {
  0%, 100% {
    transform: translateX(0px) translateY(-10px);
  }
  50% {
    transform: translateX(-10px) translateY(0px);
  }
}