.services-container {
  > .main {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @media only screen and (max-width: 1180px) {
      gap: 10px;
    }

    @media only screen and (max-width: 780px) {
      flex-direction: column;
    }

    > .header {
      width: 130%;
      margin-top: 50px;

      @media only screen and (max-width: 1130px) {
        margin-top: 100px;
        width: 100%;
      }

      @media only screen and (max-width: 1050px) {
        margin-top: 150px;
      }

      @media only screen and (max-width: 780px) {
        margin-top: 0;
      }
    }

    > .capabilities {
      padding-top: 10px;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      @media only screen and (max-width: 1130px) {
        width: 90%;
        grid-template-columns: 1fr;
      }

      @media only screen and (max-width: 980px) {
        width: 70%;
      }

      @media only screen and (max-width: 780px) {
        margin-top: 50px;
        width: 100%;
        grid-template-columns: 1fr 1fr;
      }

      @media only screen and (max-width: 570px) {
        grid-template-columns: 1fr;
      }

      > .card {
        text-align: center;

        > .content {
          > .sub-title {
            margin: 0;
          }
        }
      }
    }
  }
  
  > .support,
  > .consultation,
  > .dev {
    margin-top: 150px;
    text-align: center;

    > .description {
      margin-top: 50px;
      display: flex;
      justify-content: center;

      > .text {
        width: 100%;
        text-align: left;

        display: flex;
        flex-direction: column;
        justify-content: center;

        > h3 {
          font-size: 22px;
          margin: 0 0 5px;
        }
      }

      > .image {
        width: 100%;
        display: flex;
        justify-content: center;

        > .card {
          width: 300px;
          height: 350px;

          @media only screen and (max-width: 720px) {
            width: 100%;
            height: 200px;
          }

          > .image {
            height: 100%;
            display: flex;
            padding: 0;

            > picture > img,
            > img {
              border-radius: 8px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  > .support,
  > .consultation,
  > .dev {
    > .description {
      @media only screen and (max-width: 740px) {
        flex-direction: column;
        gap: 50px;

        > .image {
          > .card {
            width: 100%;
            height: 550px;
          }
        }
      }
    }
  }

  > .dev {
    > .description {
      @media only screen and (max-width: 740px) {
        flex-direction: column-reverse;
      }
    }
  }
  
  > .offers {
    margin-top: 100px;

    > h2 {
      text-align: center;
    }

    > .offer-container {
      margin-top: 50px;

      display: flex;
      justify-content: center;
      gap: 20px;

      > .card {
        text-align: center;

        > .content {
          > .sub-title {
            margin: 0;
          }
        }
      }
    }
  }

  > .working {
    margin-top: 100px;

    > h2 {
      text-align: left;
    }

    > .working-cards {
      margin-top: 50px;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;

      @media only screen and (max-width: 740px) {
        grid-gap: 20px;
      }

      @media only screen and (max-width: 660px) {
        grid-template-columns: 1fr;
      }

      > .card {
        > .content {
          > .title {
            > svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  > .digital-transformation {
    margin: 150px 0;
  }
  
  > .contact {
    margin: 200px 0;
    text-align: center;

    > h2 {
      margin-bottom: 50px;
    }
  }
}