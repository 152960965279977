@import "../../../../utilities/variables";

.blogs-container {
  > h2 {
    text-align: center;
  }

  > .card-container {
    margin-top: 50px;

    @media only screen and (max-width: 1000px) {
      padding: 0 20px;
    }

    @media only screen and (max-width: 900px) {
      padding: 0 10px;
    }

    > .card-area {
      grid-gap: 20px;

      @media only screen and (max-width: 900px) {
        grid-gap: 10px;
      }

      @media only screen and (max-width: 660px) {
        grid-template-columns: 1fr;
      }
      
      > a {
        text-decoration: none;
      }
      
      > a > .card {
        max-width: 300px;
        height: 100%;

        @media only screen and (max-width: 620px) {
          max-width: 620px;
        }
      }

      > a >.card > .content {
        > .sub-title {
          @media only screen and (max-width: 800px) {
            margin: 0;
          }
        }
        
        > .details {
          @media only screen and (max-width: 800px) {
            display: none;
          }
        }
      }
    }
  }
}