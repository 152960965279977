@import "../../utilities/variables";

.blog-container {
  min-height: 600px;

  > .card-area {
    > a {
      text-decoration: none;

      > .card {
        height: 100%;
        max-width: 300px;

        @media only screen and (max-width: 620px) {
          max-width: 620px;
        }
      }
    }

    @media only screen and (max-width: 1080px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }

    @media only screen and (max-width: 740px) {
      grid-template-columns: 1fr;
    }
  }
}