@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "utilities/variables";
@import "utilities/card";
@import "utilities/input";
@import "utilities/animations";
@import "utilities/text";
@import "utilities/spacing";
@import "utilities/button";
@import "utilities/spinner";

:root {
  --animation-orde: 0;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $colour2;
  background-image: linear-gradient(to right, $colour6, $colour1);
}