﻿.form-element {
  display: flex;
  flex-direction: column;
  
  > input,
    textarea {
    padding: 12px;
    border: none;
    border-radius: 8px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
    color: dimgray;
    resize: none;
  }

  > .error {
    color: red;
    font-size: 12px;
  }
}