﻿h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  font-size: 4em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.1em;
}

h5 {
  font-size: 1em;
}

@media only screen and (max-width: 1450px) {
  h1 {
    font-size: 3.5em;
  }

  h2 {
    font-size: 2.2em;
  }
}

@media only screen and (max-width: 1320px) {
  h1 {
    font-size: 3.2em;
  }

  h2 {
    font-size: 2.0em;
  }

  h3 {
    font-size: 1.4em;
  }
}

@media only screen and (max-width: 1160px) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 1.9em;
  }

  h3 {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 1080px) {
  h1 {
    font-size: 2.8em;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.2em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 990px) {
  h1 {
    font-size: 2.6em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.1em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 940px) {
  h1 {
    font-size: 2.4em;
  }

  h2 {
    font-size: 1.4em;
  }

  h3 {
    font-size: 1em;
  }

  h4 {
    font-size: 0.9em;
  }

  h5 {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 890px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 0.9em;
  }

  h4 {
    font-size: 0.85em;
  }

  h5 {
    font-size: 0.75em;
  }
}

@media only screen and (max-width: 430px) {
  h1 {
    font-size: 1.6em;
  }
}

.text-center {
  text-align: center;
}

.italic {
  font-style: italic;
}

.text-sm {
  font-size: 0.75em;
}

.text-md {
  font-size: 0.875em;
}

.text-normal {
  font-size: 1em;
}

.text-center {
  text-align: center;
}