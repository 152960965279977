﻿@import "variables";

.card-area {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;

  @media only screen and (max-width: 1160px) {
    grid-template-columns: auto auto;
    grid-gap: 50px;
  }
}

.card {
  filter: grayscale(1);
  background-color: $colour1;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 8px;

  transition: all 0.2s linear;

  &.right {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;

    > .content {
      padding: 25px;
      width: 100%;
    }

    > .image {
      width: 100%;
      height: unset;

      > picture > img,
      > img {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  &.left {
    display: flex;
    justify-content: center;

    > .content {
      padding: 25px;
      width: 100%;
    }

    > .image {
      width: 100%;
      height: unset;

      > picture > img,
      > img {
        border-radius: 8px 0 0 8px;
      }
    }
  }

  @media only screen and (max-width: 510px) {
    &.left,
    &.right {
      display: block;

      > .content {
        width: unset;
      }
      
      > .image {
        > picture > img,
        > img {
          border-radius: 8px 8px 0 0;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.has-action:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    transform: translate(-2px, -2px);
  }

  > .image {
    height: 250px;

    > picture > img,
    > img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  > .image.full {
    > picture > img,
    > img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }

  > .image.single {
    padding: 25px;
    height: unset;

    > picture > img,
    > img {
      border-radius: 0;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  > .content {
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > .title {
      @extend h5;
      @extend .mb5;
      font-weight: 800;
    }

    > .sub-title {
      @extend .italic;
      @extend .text-sm;
      @extend .mb5;
    }
    
    > .details {
      @extend .text-md;
    }
  }
}