@import "../../utilities/variables";

.full-width {
  display: initial;

  @media only screen and (max-width: 880px) {
    display: none;
  }
}

.mobile {
  display: none;
  align-self: center;
  margin-right: 40px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media only screen and (max-width: 460px) {
    margin-right: 20px;
  }

  @media only screen and (max-width: 880px) {
    display: initial;
  }

  > div {
    svg {
      font-size: 36px;
    }
  }

  > .content {
    position: absolute;
    top: 94px;
    right: -350px;
    opacity: 0;
    bottom: 0;
    width: 350px;
    height: 101vh;
    background-color: $colour1;
    background-image: linear-gradient(to right, #F7F7F7, $colour1);

    @media only screen and (max-width: 680px) {
      background-image: linear-gradient(to right, #F4F4F4, $colour1);
    }

    @media only screen and (max-width: 560px) {
      background-image: linear-gradient(to right, #F2F2F2, $colour1);
    }

    @media only screen and (max-width: 460px) {
      right: -460px;
      width: 100%;
      background-image: linear-gradient(to right, $colour6, $colour1);
    }

    transition: all 200ms ease-in-out, top 80ms linear;

    &.open {
      opacity: 1;
      right: 0;
    }

    &.scrolled {
      top: 74px;
      background-color: $colour2;
      background-image: linear-gradient(to right, #0D0D0D, #000000);
      box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;

      @media only screen and (max-width: 680px) {
        background-image: linear-gradient(to right, #101010, #000000);
      }

      @media only screen and (max-width: 560px) {
        background-image: linear-gradient(to right, #131313, #000000);
      }

      @media only screen and (max-width: 460px) {
        background-image: linear-gradient(to right, #1F1F1F, $colour2);
      }

      > ul {
        > li {
          &:hover {
            background-color: #1F1F1F;
          }

          > a {
            color: $colour1;
            font-size: 30px;
          }
        }
      }
    }

    > ul {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 10px 80px 0 50px;

      > li {
        text-align: left;
        width: 100%;
        padding: 0 20px;

        &:hover {
          background-color: #EBEBEB;
        }

        > a {
          color: $colour2;
          font-size: 30px;
        }
      }
    }
  }
}

.full-width, .mobile {
  &.scrolled {
    color: $colour1;

    ul {
      > li {
        &:hover {
          background-color: #1F1F1F;
        }

        > a {
          text-decoration: none;
          color: $colour1;
        }
      }
    }
  }

  ul {
    display: flex;

    > li {
      display: flex;
      list-style: none;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      border-radius: 20px;
      text-align: center;
      margin: 0 5px;
      transition: all 0.2s linear;


      @media only screen and (max-width: 1130px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 1100px) {
        min-width: 120px;
        display: grid;
      }

      @media only screen and (max-width: 1080px) {
        font-size: 15px;
      }

      @media only screen and (max-width: 940px) {
        min-width: 90px;
        font-size: 14px;
      }

      &:hover {
        background-color: $colour6;
      }

      > a {
        text-decoration: none;
        color: $colour2;
        padding: 10px 30px;

        @media only screen and (max-width: 1100px) {
          padding: 10px 10px;
        }
      }
    }
  }
}